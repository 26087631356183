.navOptionsList {
  height: 100vh;
  width: 10vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #4528ff; /*#303030;*/
  overflow-x: hidden;
  padding-top: 5vh;
  margin-top: 0;
  padding-left: 0;
}

#homeNavSeperator {
  height: 10vh;
}

.navOption {
  height: 4vh;
  margin-top: 2vh;
  padding-top: 1vh;
}

li {
  margin-bottom: 5vh;
}

a {
  text-decoration: none;
  color: #f8f8ff;
  font-size: 2vmin;
}
