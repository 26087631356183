#deactivateClient,
#deactivateCaretaker {
  background-color: #f38226;
}

#confirmDeleteClient,
#confirmDeleteCaretaker {
  background-color: #df1515;
}

#cancelDeleteClient,
#cancelDeleteCaretaker {
  width: 4vw;
  margin-right: 0vw;
}

.titleAndX {
  display: flex;
  justify-content: space-between;
}

.deactivateDiv {
  display: flex;
  justify-content: center;
}

#deactivDateInput {
  padding-right: 2vw;
}
