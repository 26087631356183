.HoursBoxClientsDiv {
  height: 100vh;
  width: 80vw;
  background-color: #77e0fa73;
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 10%;
  margin-left: -3vw;
}

#hoursHeaderClient {
  font-size: 4vh;
  padding-top: 0vh;
}

#currentWeekHeaderHoursClients {
  margin-top: -3vh;
  margin-bottom: 4vh;
}

#clientTable {
  margin-left: 2vw;
}
.HoursBoxClientsDiv td {
  font-size: 3vh;
  padding-right: 3vw;
  padding-top: 0.5vh;
  border-bottom: 1px solid black;
}
