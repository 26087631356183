.aptCalendarDiv a {
  color: black;
}

#timeAptLabel {
  color: whitesmoke;
  font-size: 1.8vh;
}

#appointmentsH1 {
  font-size: 4vh;
  color: #b11616;
}

#clientAptLabel {
  font-size: 2vmin;
}
