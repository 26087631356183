:root {
  --primary: #4628ff;
  --warning: #ffd028;
  --danger: #eb3f27;
  --success: #57f178;
  --white: #fdfdfd;
  --black: #181717;
  --purpleish: #151515; /* #c603fc;  */
}

.btn {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 0 2vw;
}

.btn:hover {
  transform: translateY(-2px);
}

/* Button Colors and Styles */

.btn--primary--solid {
  background-color: var(--primary);
  color: var(--black);
  border: none;
}

.btn--warning--solid {
  background-color: var(--warning);
  color: var(--dark);
  border: none;
}

.btn--success--solid {
  background-color: var(--success);
  color: var(--black);
  border: none;
}

.btn--danger--solid {
  background-color: var(--danger);
  color: var(--white);
  border: none;
}

.btn--primary--solid {
  background-color: var(--primary);
  color: var(--white);
  border: none;
}

.btn--primary--outline {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
}

.btn--warning--outline {
  background-color: transparent;
  color: var(--warning);
  border: 2px solid var(--warning);
}

.btn--success--outline {
  background-color: transparent;
  color: var(--success);
  border: 2px solid var(--success);
}

.btn--danger--outline {
  background-color: transparent;
  color: var(--danger);
  border: 2px solid var(--danger);
}

/* Button Sizes */

.btn--medium {
  padding: 10px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 15px 30px;
  font-size: 20px;
}
