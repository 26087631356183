.addShiftBoxDiv {
  height: 120vh;
  width: 80vw;
  background-color: #745fff46;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -3vw;
}

#addShiftH2 {
  font-size: 4vh;
}

textarea {
  height: 10vh;
  width: 25vw;
}

select {
  height: 3vh;
  width: 25vw;
}

option {
  font-size: 3vh;
}

label {
  font-size: 3.5vh;
  margin-right: 1vw;
}

select {
  font: inherit;
}

h2 {
  font-size: 5vw;
  padding-top: 1vh;
}

#caretakerSelect {
  font-size: 2vh;
}

#clientSelect {
  font-size: 2vh;
}

.formSep {
  height: 4vh;
}

.optionSelectDiv {
  font-size: 2vh;
}

#repeatSelect {
  height: 4vh;
  font-size: 2vh;
}
