.SignInDiv {
  height: 100vh;
  justify-content: center;
}

#googleButtonDiv {
  padding-top: 25vh;
}

#welcomeDiv {
  padding-top: 15vh;
}

#welcomeDiv h1 {
  font-size: 8vh;
}

#welcomeDiv h3 {
  font-size: 3vh;
}
