.HoursBoxDiv {
  height: 140vh;
  width: 80vw;
  background-color: #77fab273;
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 10%;
  margin-left: -3vw;
}

#hoursHeader {
  font-size: 4vh;
  padding-top: 0vh;
}

#currentWeekHeader {
  margin-top: -3vh;
  margin-bottom: 4vh;
}

#caretakerTable {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}
.caretakerHoursBoxTD {
  font-size: 3.5vh;
  padding-right: 2vw;
  padding-top: 1vh;
  border-bottom: 1px solid black;
}

.byClientTD {
  font-size: 2.5vh;
}

.hoursBoxLabel {
  font-size: 3.5vh;
  padding-right: 2vw;
  padding-top: 0.5vh;
  border-bottom: 1px solid black;
}

.trByClient {
  background-color: #57b48173;
}

.caretakerRowHours {
  background-color: #b8eed373;
}
