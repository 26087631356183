.calendarDiv a {
  color: black;
}

#timeEventLabel {
  color: whitesmoke;
  font-size: 0.7vmax;
  margin-top: -1vh;
  margin-bottom: 1.5vh;
  font-weight: 500;
}

#clientCaretakerEventLabel {
  margin-top: -1.4vh;
}

#editedTimeLabel {
  margin-top: 0vh;
}

#dayAndDateLabel {
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-size: 0.6vmax;
  font-weight: bold;
}

#editedLabel {
  margin-bottom: 0vh;
}

#clientLabelRender {
  font-size: 0.7vmax;
}

#caretakerLabelRender {
  font-size: 0.7vmax;
  margin-top: 0vh;
  margin-top: -0.5vh;
}

#dashP {
  margin-top: -0.5vh;
}

@media only screen and (max-width: 600px) {
  #dayAndDateLabel {
    margin-top: 0vh;
    margin-bottom: 1vh;
    font-size: 1.6vmax;
    font-weight: bold;
  }
}
