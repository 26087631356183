#confirmShiftDetailsButton {
  background-color: #27ad27c7;
  color: black;
}

#cancelButton {
  background-color: #d6d625d2;
}

#deleteButton {
  background-color: #cc1b1bc9;
}

#deleteRepeatingButton {
  background-color: #ff7b00;
}
