.addCaretakerDiv {
  height: 80vh;
  width: 80vw;
  background-color: #6673e785;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -3vw;
}

input {
  height: 3vh;
  font-size: 2.5vh;
  width: 25vw;
}
