.mileageInputDivUser {
  height: 60vh;
  width: 80vw;
  background-color: #689cfdb6;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -4vw;
}

#mileageClientInputUser {
  display: flex;
  margin: auto;
  justify-content: center;
  padding-top: 3vh;
}

#mileageClientInputUser label {
  margin-right: 5vw;
}
