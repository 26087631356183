.caretakerFilterDiv {
  width: 80vw;
  margin-left: 15vw;
  border-bottom: 2px black solid;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color: #cc98fd8e;
}

.filterButton {
  width: 6vw;
  background-color: #14d314;
}

.filterButtonClient {
  width: 6vw;
  background-color: #14d314;
}

#checkAllButtonCT {
  background-color: #14d31498;
  margin-right: 0.5vw;
  margin-top: 5vh;
}

#uncheckAllButtonCT {
  background-color: #f14c1aad;
  margin-right: 0.5vw;
  margin-top: 5vh;
}

#checkAllButtonClient {
  background-color: #14d31498;
  margin-right: 0.5vw;
  margin-top: 5vh;
}

#uncheckAllButtonClient {
  background-color: #f14c1aad;
  margin-right: 0.5vw;
  margin-top: 5vh;
}

#applyButton {
  background-color: #35edfac4;
  margin-right: 4vw;
  height: 4vh;
  width: 6vw;
}

#filterTable {
  margin-left: 15vw;
}

#checkUncheckApplyRow {
  padding-left: 10vw;
}
