.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.hoursAndAddShiftDiv {
  display: flex;
  width: 100vw;
  margin-left: -8vw;
}

.addClientAndCaretakerDiv {
  display: flex;
  justify-content: space-around;
  width: 100vw;
}

.App-link {
  color: #61dafb;
}

Button a {
  color: #ffffff;
  text-decoration: none; /* no underline */
}

#buttonsDiv {
  margin-bottom: 10vh;
  margin-top: 3vh;
}

.scheduleDiv {
  width: 75vw;
  padding-left: 10vw;
  margin: auto;
}
