.repeatOptions {
  height: 65vh;
}

.repeatDayAndCheckboxDiv label {
  font-size: 2.5vh;
}

.repeatDayAndCheckboxDiv label {
  margin-right: -5vw;
}
