.timeOffRequestUserDiv {
  height: 60vh;
  width: 80vw;
  background-color: #5f3ef3b6;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -4vw;
}

#caretakerSelectTimeOff {
  display: flex;
  justify-content: center;
}

#caretakerSelectTimeOff {
  display: flex;
  justify-content: center;
}
