.editCaretakersDiv {
  height: 100vh;
  width: 80vw;
  background-color: #e766d285;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -4vw;
}
.editCaretakersDiv td {
  font-size: 2.5vh;
  padding-right: 3vw;
  padding-top: 0.5vh;
  border-bottom: 1px solid black;
}

.caretakerPayInput {
  width: 5vw;
}

.caretakerColorInput {
  width: 5vw;
}

.updateCaretakerButtonTD {
  margin-right: 4vw;
  padding-right: 1vw;
}

.updateCaretakerButton {
  background-color: #1fc51f;
}

#deleteCaretakerButtonTD {
  margin-right: 4vw;
  padding-right: 1vw;
}

#deleteCaretakerButton {
  background-color: red;
}
