.addAppointmentBoxDiv {
  height: 80vh;
  width: 80vw;
  background-color: #fd766cab /*  #e7f0718c  */;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -3vw;
}

h2 {
  font-size: 3vh;
  padding-top: 1vh;
}

label {
  font-size: 3.5vh;
}

.formSep {
  height: 6 vh;
}
