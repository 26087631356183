.deactivatedTD {
  min-width: 7vw;
  margin-right: 4vw;
  font-size: 2vmax;
}

.deactivatedButton {
  font-size: 1.2vmax;
  margin-right: 2vw;
}

.deactivatedButtonTD {
  justify-content: center;
}

.clientTR {
  margin-left: 5vw;
}
