#editClientsDiv {
  height: 100vh;
  width: 80vw;
  background-color: #66e7c785;
  margin: auto;
  margin-top: 5vh;
  border-radius: 10%;
  margin-left: -4vw;
}

.editClientsDiv td {
  font-size: 2.5vh;
  padding-right: 3vw;
  padding-top: 0.5vh;
  border-bottom: 1px solid black;
}

#deactivatedClientsTable {
  margin-left: 2vw;
}

.editClientsDiv button {
  font-size: 1.5vh;
}

.updateClientButton {
  background-color: #1fc51f;
}

.deleteClientButton {
  background-color: red;
}

.clientRateInput {
  width: 5vw;
}
